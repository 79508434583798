import { splitHexColor } from './splitHexColor';

export const hexToRgba = (hexColor: string) => {
  const { pureHex, alphaPercent } = splitHexColor(hexColor);
  const bigint = parseInt(pureHex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alphaPercent / 100})`;
};
