import { isValidHex } from './isValidHex';

export function splitHexColor(hexColor: string) {
  if (!isValidHex(hexColor)) {
    throw new Error('Input must be in the format #RRGGBB or #RRGGBBAA');
  }

  const r = hexColor.slice(1, 3);
  const g = hexColor.slice(3, 5);
  const b = hexColor.slice(5, 7);

  let alphaPercent = 100;

  if (hexColor.length === 9) {
    const a = hexColor.slice(7, 9);
    const alphaDecimal = parseInt(a, 16);
    alphaPercent = (alphaDecimal / 255) * 100;
  }

  const pureHexColor = `#${r}${g}${b}`;

  return { pureHex: pureHexColor, alphaPercent };
}
