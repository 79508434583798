import { Context, Settings } from 'entities';
import { makeAutoObservable, runInAction } from 'mobx';
import { createContext, logContextEnter, renameContext, leaveContext, loadContexts } from './api';
import { defined } from 'shared/lib/checks';
import events from 'entities/event/events';
import { DateTime } from 'luxon';
import notificationsStore from 'entities/notification/notificationsStore';
import i18n from 'entities/localization/i18n';
import { getURLParam, replaceURLParam } from 'shared/lib/url';
import { contextURLParamName } from './config';
import { panelURLParamName } from 'entities/panel/config';
import templateStore from 'entities/data/templates/templateStore';

class ContextStore {
  private _pathContextId: string | null = null;
  /**
   * Маркер загрузки данных.
   */
  public loading = true;
  /**
   * Пользовательские настройки контекста.
   */
  public settings: Settings | null = null;
  /**
   * Список контекстов пользователя.
   */
  public contexts: Context[] = [];
  /**
   * Текущий контекст пользователя.
   */
  public currentContext: Context | null = null;

  public get currentContextId(): number | null {
    return this.currentContext?.id ?? null;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public async loadContexts() {
    runInAction(() => {
      this.loading = true;
      this.contexts = [];
    });

    try {
      const contexts = await loadContexts();
      runInAction(() => {
        this.contexts = contexts;
      });
      const URLContextId = getURLParam(contextURLParamName) ? Number(getURLParam(contextURLParamName)) : undefined;
      const contextId = URLContextId ?? contexts[0]?.id;
      if (contextId) {
        await this.changeCurrentContext(contextId);
      } else {
        await this.addContext(DateTime.local().toFormat('yyyyMMddHHmmss'));
      }
    } catch (ex) {
      notificationsStore.notify('error', i18n.strings.Errors.Contexts.LoadError.Title, (ex as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async addContext(title: string, asCurrent = true) {
    title = title.trim();
    if (title) {
      const newContext = await createContext(title);
      if (newContext.id) {
        runInAction(() => {
          this.contexts = [newContext, ...this.contexts];
        });
        if (asCurrent) {
          await this.changeCurrentContext(newContext.id);
          window.location.reload();
        }
      }
    }
  }

  public async renameContext(contextId: number, title: string) {
    title = title.trim();
    if (title) {
      await renameContext(contextId, title);
      this.setContextName(contextId, title);
    }
  }

  public async changeCurrentContext(contextId: number) {
    const context = this.contexts.find((x) => x.id === contextId);
    const prevContextId = this.currentContextId;
    if (context) {
      await logContextEnter(defined(context.id));
      runInAction(() => {
        this.currentContext = context;
        if ((getURLParam(contextURLParamName) ? Number(getURLParam(contextURLParamName)) : undefined) !== context.id) {
          replaceURLParam(panelURLParamName);
        }
        replaceURLParam(contextURLParamName, context.id?.toString());
      });
      const newContextId = this.currentContextId;
      if (newContextId) {
        void events.resubscribeToContext(newContextId, prevContextId);
      }
    } else {
      runInAction(() => {
        this.currentContext = null;
      });
      void events.resubscribeToContext(null, prevContextId);

      // Загрузка шаблонов пользователя
      await templateStore.loadTemplates(contextId);
    }
  }

  private setContextName(contextId: number, title: string) {
    if (title) {
      const context = this.contexts.find((x) => x.id === contextId);
      if (context) {
        context.title = title;
      }
    }
  }

  public async leaveContext(contextId: number, userId: number) {
    await leaveContext(contextId, userId);

    // Чтобы не выяснять, на какой контекст переключаться,
    // отдаем это на откуп серверу перезагружая контексты.
    await this.loadContexts();
  }
}

export default new ContextStore();
